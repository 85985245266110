const resource = {
    en: {
        heading: 'You have been registered',
        message: 'Welcome to the Planungsmatrix tool. Shortly, you will receive a mail from us, which includes a link to verify your account. As soon as your account is verified, you will be abl to use the Planungsmatrix with all its features.',
        home_button: 'Back to Home'
    },
    de: {
        heading: 'Sie wurden registriert',
        message: 'Willkommen bei dem Planungsmatrix-Tool. Sie werden in Kürze von uns eine E-Mail mit einem Link zur Verifierung Ihres Accounts erhalten. Sobald Sie Ihren Account verifiziert haben, können Sie sich normal einloggen und alle Funktionen der Planungsmatrix verwenden.',
        home_button: 'Zurück zur Homepage'
    }
}

export default resource;